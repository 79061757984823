<template>
  <app-page
    ref="page"
    :toolbar="toolbar"
    :list="list"
    :creator="creator"
    :updater="updater"
  >
    <template #content="{ items }">
      <app-menu-view
        :items="items"
        view-height="calc(100vh - 320px)"
        @select="onSelect"
        @remove="onRemove"
      />
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Library',
  mixins
};
</script>
