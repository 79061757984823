import request from '@/utils/request';

export default {
  methods: {
    // 自定义请求数据
    async customRequest(vm, model) {
      const { fillItems, setRowHeader, setColumnHeader, getItems } = this;
      // 非复制时执行
      if (!model?.copyable) {
        // 清空数据
        vm.items = [];
        // 填充空白数据
        fillItems(vm);
        // 设置行头
        setRowHeader(vm);
        // 设置表头
        setColumnHeader(vm);
      }
      // 查询数据
      await getItems(vm, model);
    },
    // 查询数据并赋值
    async getItems(vm, model) {
      const { url, method, params, items } = vm;
      const data = Object.assign(
        {
          startPage: 1,
          pageSize: 500
        },
        params,
        model
      );
      // 可复制
      const copyable = model?.copyable;
      // 获得菜单
      const result = await request({
        url,
        method,
        data
      });
      const menu = result.data.records;
      // 回显数据
      menu.forEach(item => {
        // 跳过没有坐标的数据
        if (item.tableX == null || item.tableY == null) {
          return;
        }
        // 位置定位
        const current = items[item.tableY][item.tableX];

        current.totalNum = item.totalNum;
        current.subscribeNum = item.subscribeNum;

        if (item.isSet == 1) {
          // 粉类
          const powder = current.powders[item.isPush];
          // 复制时，跳过有名称的菜
          if (copyable && powder.name) {
            return;
          }
          powder.picPath = item.picPath;
          powder.name = item.name;
          // 非复制时，设置编号、是否上架
          if (!copyable) {
            powder.id = item.id;
            powder.isOut = item.isOut;
          }
          // 设置粉
          current.name = item.name.split('(')[0];
          if (!copyable) {
            current.isOut = item.isOut;
          }
        } else if (item.isSet == 2) {
          // 面类
          const noodle = current.noodles[item.isPush];
          // 复制时，跳过有名称的菜
          if (copyable && noodle.name) {
            return;
          }
          noodle.picPath = item.picPath;
          noodle.name = item.name;
          if (!copyable) {
            noodle.id = item.id;
            noodle.isOut = item.isOut;
          }
          // 设置面
          current.name = item.name.split('(')[0];
          if (!copyable) {
            current.isOut = item.isOut;
          }
        } else {
          // 复制时，跳过有名称的菜
          if (copyable && current.name) {
            return;
          }
          // 设置其他类别
          current.picPath = item.picPath;
          current.name = item.name;
          if (!copyable) {
            current.id = item.id;
            current.isOut = item.isOut;
          }
        }
      });
    }
  }
};
