import categoryFieldChange from './category-field-change';

export default {
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          name: undefined,
          type: undefined,
          category: undefined
        },
        fields: this.getToolbarFields()
      }
    };
  },
  methods: {
    getToolbarFields() {
      return [
        {
          label: '菜名',
          prop: 'name',
          placeholder: '请输入要搜索的菜名',
          clearable: true
        },
        {
          label: '类型',
          prop: 'type',
          type: 'select',
          clearable: true,
          options: [
            { label: '所有', value: undefined },
            { label: '早餐', value: 1 },
            { label: '中餐、晚餐', value: 2 },
            { label: '外卖', value: 4 }
          ],
          // 类型变更，动态改变分类选项
          change: categoryFieldChange
        },
        {
          label: '分类',
          prop: 'category',
          placeholder: '请选择要搜索分类',
          type: 'select',
          clearable: true,
          options: [{ label: '所有', value: undefined }]
        }
      ];
    }
  }
};
