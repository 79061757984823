export default {
  data() {
    return {
      dialog: {
        visible: false,
        width: '860px',
        type: undefined,
        category: undefined
      }
    };
  },
  methods: {
    // 选择对话框的菜单选中
    onSelect(e) {
      const { number } = this;
      const { selected } = number;
      // 关键字段赋值
      selected.picPath = e.picPath;
      selected.name = e.name;
      selected.description = e.description;
      selected.maxNum = 2;
      selected.price = e.price;
      // 打开数量选择对话框
      number.visible = true;
    }
  }
};
