export default {
  data() {
    return {
      dialog: {
        visible: false,
        width: '860px',
        type: undefined,
        category: undefined,
        selected: undefined
      }
    };
  },
  methods: {
    // 选择对话框的菜单选中
    onSelect(e) {
      if (e.type === 1 && (e.category === 2 || e.category === 1)) {
        const {selected} = this.dialog;
        // 只修改图片、名称
        selected.picPath = e.picPath;
        selected.name = e.name;
        // 粉
        selected.powders?.forEach(item => {
          item.picPath = e.picPath;
          item.name = e.name + item.suffix;
        });
        // 面
        selected.noodles?.forEach(item => {
          item.picPath = e.picPath;
          item.name = e.name + item.suffix;
        });
      } else {
        this.number.selected = {
          name: e.name,
          picPath: e.picPath,
          description: e.description,
          price: e.price
        }
        this.number.visible = true;
      }
    }
  }
};
