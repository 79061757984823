export default {
  methods: {
    // 合并单元格
    spanMethod({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        // 类型列
        const span = { 0: [4, 1], 4: [8, 1] };
        return span[rowIndex] || [0, 0];
      } else {
        return undefined;
      }
    },
    // 行样式
    rowClassName({ row, rowIndex }) {
      if (rowIndex < 4) {
        return 'lunch';
      } else {
        return 'dinner';
      }
    },
    // 单元格样式
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < 2) {
        return;
      }
      var cell = row[columnIndex - 2];
      if (cell?.isOut == 1) {
        return 'outed';
      }
    }
  }
};
