<template>
  <app-page
    ref="page"
    :list="list"
    :creator="creator"
    @cell-click="onCellClcik"
    @clear-item="onClearItem"
    @data-change="onDataChange"
  >
    <template #header>
      <div class="toolbar">
        <el-alert type="warning" :closable="false" :title="title" />
        <div class="action" ref="header">
          <el-radio-group
            class="group"
            fill="#E6A23C"
            :value="list.params.canteenType"
            @input="onCanteenTypeChange"
          >
<!--            <el-radio-button :disabled="list.params.canteenType === 2 && list.params.canteenType !== 0" :label="1">一食堂</el-radio-button>-->
<!--            <el-radio-button :disabled="list.params.canteenType === 1 && list.params.canteenType !== 0" :label="2">二食堂</el-radio-button>-->
            <el-radio-button :disabled="canteenType === 2 && canteenType !== 999 " :label="1">一食堂</el-radio-button>
            <el-radio-button :disabled="canteenType === 1 && canteenType !== 999 " :label="2">二食堂</el-radio-button>
          </el-radio-group>
          <el-button-group class="group">
            <el-button
              :loading="loading"
              icon="el-icon-arrow-left"
              @click="onPrevious"
            >
              上周
            </el-button>
            <el-button
              :loading="loading"
              icon="el-icon-arrow-up"
              @click="onCurrent"
            >
              返回
            </el-button>
            <el-button
              :loading="loading"
              icon="el-icon-arrow-right"
              @click="onNext"
            >
              下周
            </el-button>
          </el-button-group>
          <el-button-group class="group">
            <el-button
              :loading="loading"
              :disabled="disabledCopy"
              icon="el-icon-copy-document"
              @click="onCopy"
            >
              复制
            </el-button>
            <el-button
              :loading="loading"
              :disabled="disabled"
              icon="el-icon-check"
              @click="onSave"
            >
              保存
            </el-button>
            <el-button
              :loading="loading"
              :disabled="disabled"
              icon="el-icon-finished"
              type="primary"
              @click="onPublish"
            >
              发布
            </el-button>
          </el-button-group>
        </div>
      </div>
    </template>
    <template #dialog>
      <app-menu-dialog
        :visible.sync="dialog.visible"
        v-bind="dialog"
        @select="onSelect"
      />
      <app-number-dialog
          :visible.sync="number.visible"
          v-bind="number"
          @cancel="onNumberCancel"
          @confirm="onNumberConfirm"
      />
      <el-dialog
          :title="saveDialog.selected.name"
          :visible.sync="saveDialog.saveVisible"
          width="40%" @close="initializeSaveDialog">
        <el-form label-width="120px">
          <el-form-item label="修改名称">
            <el-input v-model="saveDialog.saveName" />
          </el-form-item>
          <el-form-item label="修改总数">
            <el-radio-group v-model="saveDialog.saveTotal">
              <el-radio-button :label="10">10份</el-radio-button>
              <el-radio-button :label="20">20份</el-radio-button>
              <el-radio-button :label="30">30份</el-radio-button>
              <el-radio-button :label="40">40份</el-radio-button>
              <el-radio-button :label="50">50份</el-radio-button>
              <el-radio-button :label="60">60份</el-radio-button>
              <el-radio-button :label="100">100份</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="或输入数量">
            <el-input-number v-model="saveDialog.saveTotal" />
          </el-form-item>
          <el-form-item label="已预约数量">
           <span style="font-size: 18px"> {{saveDialog.subscribeNum}} 份</span>
          </el-form-item>
          <el-divider></el-divider>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="saveDialog.saveVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveChange">确 定</el-button>
        </span>
      </el-dialog>
    </template>
  </app-page>
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Week',
  mixins,
  data(){
    return{
      saveDialog:{
        saveVisible:false,
        saveTotal:0,
        selected:{},
        saveName:'',
        subscribeNum:0
      }
    }
  },
  methods:{
    initializeSaveDialog(){ // 初始化
      this.saveDialog.saveVisible = false;
      this.saveDialog.saveTotal = 0;
      this.saveDialog.selected = {};
      this.saveDialog.saveName = '';
      this.saveDialog.subscribeNum = 0;
    },
    saveChange(){
      this.saveDialog.selected.name = this.saveDialog.saveName;
      this.saveDialog.selected.totalNum = this.saveDialog.saveTotal;
      this.saveDialog.saveVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.toolbar {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: white;

  .title {
    display: flex;
    align-items: center;
  }

  .action {
    display: flex;
    margin-top: 12px;

    .group {
      margin-right: 12px;
    }
  }
}

::v-deep {
  .el-table table {
    border: thin solid black;
    border-bottom: none;
    border-right: none;
  }

  .el-table__cell {
    border-bottom: thin solid black !important;
    border-right: thin solid black !important;
    cursor: pointer;
  }

  .breakfast {
    background-color: #fdf6ec;
  }

  .lunch {
    background-color: #fde2e2;
  }

  .dinner {
    background-color: #e1f3d8;
  }

  .outed {
    color: #67c23a;
  }
}
</style>
