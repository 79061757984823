<template>
  <app-tabs v-model="active">
    <el-tab-pane label="每周菜单" lazy>
      <week />
    </el-tab-pane>
    <el-tab-pane label="每周外卖" lazy>
      <takeout />
    </el-tab-pane>
    <el-tab-pane label="菜品管理" lazy>
      <library />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import week from './week';
import takeout from './takeout';
import library from './library';

export default {
  name: 'Menu',
  components: { week, takeout, library },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
