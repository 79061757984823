import toolbar from './toolbar';
import list from './list';
import listSpan from './list-span';
import listCustomRequest from './list-custom-request';
import listCustomRequestFill from './list-custom-request-fill';
import menuDialog from './menu-dialog';
import creator from './creator';
import numberDialog from './number-dialog';

export default [
  toolbar,
  list,
  listSpan,
  listCustomRequest,
  listCustomRequestFill,
  creator,
  menuDialog,
  numberDialog
];
