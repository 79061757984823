import dayjs from 'dayjs';
import request from '@/utils/request';
import range from 'lodash/range';
import compact from 'lodash/compact';

export default {
  data() {
    return {
      // 定义列表组件属性
      list: {
        pages: false,
        action: false,
        url: '/canteen/menu/getMenuList',
        method: 'post',
        params: {
          types: [1, 2, 3],
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
          startDate: dayjs()
            .add(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .add(1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD')
        },
        fields: this.getListFields(),
        spanMethod: this.spanMethod,
        highlightCurrentRow: false,
        rowClassName: this.rowClassName,
        cellClassName: this.cellClassName,
        customRequest: this.customRequest
      },
      // 变更后的菜单
      items: [],
      canteenType:Number(sessionStorage.getItem('canteenType'))
    };
  },
  methods: {
    // 表格字段定义
    getListFields() {
      return [
        { label: '类型', prop: 'type', width: 50, align: 'center' },
        { label: '套餐', prop: 'combo', width: 50, align: 'center' },
        { label: '类别', prop: 'category', width: 120, align: 'center' },
        {
          label: '星期一',
          prop: '0.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 0
        },
        {
          label: '星期二',
          prop: '1.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 1
        },
        {
          label: '星期三',
          prop: '2.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 2
        },
        {
          label: '星期四',
          prop: '3.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 3
        },
        {
          label: '星期五',
          prop: '4.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 4
        },
        {
          label: '星期六',
          prop: '5.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 5
        },
        {
          label: '星期天',
          prop: '6.name',
          width: 180,
          align: 'center',
          type: 'menu',
          index: 6
        }
      ];
    },
    // 菜单数据变更
    onDataChange(items) {
      this.items = items;
    },
    // 修改数量
    async onNumberChange(row) {
      try {
        const { value } = await this.$prompt('请输入数量', '修改数量', {
          inputValue: row.number,
          inputPlaceholder: '修改当前类别（行）的数量',
          inputValidator(value) {
            if (isNaN(value)) {
              return '必须为数字';
            } else if (value <= 0) {
              return '必须大于0';
            } else {
              return true;
            }
          }
        });
        // 转换成数值型
        row.number = parseInt(value);
        // 替换括号内的数量
        if (row.category.includes('(')) {
          row.category = row.category.replace(/\(\d+\)/, `(${row.number})`);
        } else {
          row.category = `${row.category}(${row.number})`;
        }
        // 替换当前行所有数量
        range(0, 6).forEach(i => {
          row[i].totalNum = row.number;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 单击菜单单元格，弹出选择对话框
    onCellClcik(row, column) {
      // 单击类别行，修改数量
      const isCategory = column.property?.includes('category');
      if (isCategory) {
        this.onNumberChange(row, column);
      }
      // 只能单击星期内的单元格
      const isName = column.property?.includes('name');
      if (isName) {
        // 匹配属性中的数字做为星期下标
        const index = column.index;
        const { dialog } = this;
        // 选中的单元格
        const selected = row[index];
        const { type, category, isOut } = selected;
        // 上架的不能再改了
        if (isOut == 1) {
          // 粉面不修改
          if (selected.type === 1 && (selected.category === 2 || selected.category === 1)){
            return;
          }else {
            this.saveDialog.saveVisible = true
            this.saveDialog.selected = selected
            this.saveDialog.saveTotal = selected.totalNum
            this.saveDialog.subscribeNum = selected.subscribeNum
            this.saveDialog.saveName = selected.name
            return;
          }
        }
        // 特例，中餐B餐，第24行，有粉面饭类别
        if (row.y == 23) {
          dialog.type = 1;
          dialog.category = [1, 2, 7];
        } else {
          // 缓存查询参数，中晚餐一样的类别
          dialog.type = type == 2 || type == 3 ? 2 : type;
          dialog.category = category;
        }
        // 缓存选中的单元格
        dialog.selected = selected;
        // 显示窗口
        this.number.selected = {
          name: '',
          picPath: '',
          description: '',
          price: ''
        }
        dialog.visible = true;
      }
    },
    // 清空单元格菜单信息
    async onClearItem(row, field) {
      // 星期下标
      const index = field.index;
      const menuItem = row[index];
      // 删除细类
      if (menuItem.powders) {
        let ids = menuItem.powders.map(item => item.id);
        // 过滤掉假值
        ids = compact(ids);
        if (ids.length > 0) {
          await request.post('/canteen/menu/delMenu', { ids });
        }
        // 重置细类
        menuItem.powders.forEach(item => {
          item.id = undefined;
          item.picPath = undefined;
          item.name = '';
          item.isOut = 0;
        });
      } else if (menuItem.noodles) {
        let ids = menuItem.noodles.map(item => item.id);
        // 过滤掉假值
        ids = compact(ids);
        if (ids.length > 0) {
          await request.post('/canteen/menu/delMenu', { ids });
        }
        // 重置细类
        menuItem.noodles.forEach(item => {
          item.id = undefined;
          item.picPath = undefined;
          item.name = '';
          item.isOut = 0;
        });
      } else {
        const id = menuItem.id;
        if (id) {
          await request.post('/canteen/menu/delMenu', { id });
        }
      }
      // 重置菜单
      menuItem.id = undefined;
      menuItem.picPath = undefined;
      menuItem.name = '';
      menuItem.isOut = 0;
    }
  }
};
