import request from '@/utils/request';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import flatMap from 'lodash/flatMap';

dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    // 提示框标题
    title() {
      const week = dayjs(this.list.params.startDate).week();
      const { startDate, endDate } = this.list.params;
      return `现在要发布的是：${startDate}~${endDate}，第${week}周的外卖。\
绿色字体代表的是已发布的。`;
    },
    // 收集周菜单数据
    menuWeek() {
      const { items } = this;
      const week = [];
      for (let i = 0; i < 7; i++) {
        const day = [];
        items.forEach(item => {
          const menuItem = item[i];
          // 空名称跳过或上架的跳过
          if (menuItem.name == '' || menuItem.isOut == 1) {
            return;
          }
          day.push(menuItem);
        });
        if (day.length > 0) {
          week.push(day);
        }
      }
      return week;
    },
    // 将多维数组展开为1维数组
    menu() {
      return flatMap(this.menuWeek);
    },
    // 按钮是否可用
    disabled() {
      const hasLength = this.menuWeek.length == 0;
      const hasIsOut =
        this.menuWeek.filter(item => item.isOut == 0).length == 0;
      return hasLength && hasIsOut;
    }
  },
  methods: {
    // 切换食堂
    async onCanteenTypeChange(e) {
      const { list, $refs } = this;
      const { params } = list;
      params.canteenType = e;
      await $refs.page.getItems();
    },
    // 上一周
    async onPrevious() {
      const { list, $refs } = this;
      const { params } = list;
      // 以开始日期获得周，再减1周
      const { startDate } = params;
      params.startDate = dayjs(startDate)
        .subtract(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs(startDate)
        .subtract(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
    },
    // 返回发布周
    async onCurrent() {
      this.loading = true;
      const { list, $refs } = this;
      const { params } = list;
      // 以当前日期获得周，再加1周
      params.startDate = dayjs()
        .add(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs()
        .add(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
      this.loading = false;
    },
    // 下一周
    async onNext() {
      const { list, $refs } = this;
      const { params } = list;
      // 以开始日期获得周，再加1周
      const { startDate } = params;
      params.startDate = dayjs(startDate)
        .add(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs(startDate)
        .add(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
    },
    // 保存
    async onSave(e, isOut = 0) {
      const { menu, $refs } = this;
      try {
        this.loading = true;
        menu.forEach(item => (item.isOut = isOut));
        await request.post('/canteen/menu/saveMenu', menu);
        await $refs.page.getItems();
      } finally {
        this.loading = false;
      }
    },
    // 发布
    async onPublish() {
      try {
        await this.$alert(
          '菜单一但发布就有可能有人预约，所以就不能再修改了，确认发布吗?',
          { confirmTextButton: '确认发布' }
        );
        await this.onSave(undefined, 1);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
