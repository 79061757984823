import request from '@/utils/request';

export default {
  methods: {
    // 自定义请求数据
    async customRequest(vm) {
      const { fillItems, setRowHeader, setColumnHeader, getItems } = this;
      // 先清空数据
      vm.items = [];
      // 填充空白数据
      fillItems(vm);
      // 设置行头
      setRowHeader(vm);
      // 设置表头
      setColumnHeader(vm);
      // 查询数据
      await getItems(vm);
    },
    // 查询数据并赋值
    async getItems(vm) {
      const { url, method, params, items } = vm;
      const data = Object.assign(
        {
          startPage: 1,
          pageSize: 500
        },
        params
      );
      // 获得菜单
      const result = await request({ url, method, data });
      const menu = result.data.records;
      // 回显数据
      menu.forEach(item => {
        // 跳过没有坐标的数据
        if (item.tableX == null || item.tableY == null) {
          console.log(`日期：${item.menuDate}, 名称：${item.name}，空坐标。`);
          return;
        }
        // 位置定位
        const current = items[item.tableY][item.tableX];
        // 赋值
        current.id = item.id;
        current.picPath = item.picPath;
        current.name = item.name;
        current.description = item.description;
        current.price = item.price;
        current.totalNum = item.totalNum;
        current.maxNum = item.maxNum;
        current.isOut = item.isOut;
      });
    }
  }
};
