export default {
  data() {
    return {
      number: {
        visible: false,
        width: '680px',
        selected: undefined,
        ifSelectable:false
      }
    };
  },
  methods: {
    onNumberCancel() {
      this.dialog.visible = false;
      this.number.visible = false;
    },
    onNumberConfirm(e) {
      const { dialog, number } = this;
      number.selected.totalNum = e.total;
      // number.selected.maxNum = e.selectable;
      dialog.visible = false;
      number.visible = false;

      //
      const { selected } = this.dialog;
      // 只修改图片、名称
      selected.picPath = number.selected.picPath;
      selected.name = number.selected.name;
      // 粉
      selected.powders?.forEach(item => {
        item.picPath = number.selected.picPath;
        item.name = number.selected.name + item.suffix;
      });
      // 面
      selected.noodles?.forEach(item => {
        item.picPath = number.selected.picPath;
        item.name = number.selected.name + item.suffix;
      });
      selected.totalNum = e.total
    }
  }
};
