import request from '@/utils/request';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import flatMap from 'lodash/flatMap';

dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    // 提示框标题
    title() {
      const week = dayjs(this.list.params.startDate).week();
      const { startDate, endDate } = this.list.params;
      return `现在要发布的是：${startDate}~${endDate}，第${week}周的菜单。\
绿色字体代表的是已发布的。`;
    },
    // 收集周菜单数据
    menuWeek() {
      // 空名称或上架的跳过
      const skiped = menuItem => menuItem.name == '';
      return this.getMenuWeek(skiped);
    },
    // 将多维数组展开为1维数组
    menu() {
      return flatMap(this.menuWeek);
    },
    // 能保存和发布，空名称或上架的菜单不能保存和发布
    disabled() {
      const hasLength = this.menuWeek.length == 0;
      const hasIsOut =
        this.menuWeek.filter(item => item.isOut == 0).length == 0;
      return hasLength && hasIsOut;
    },
    // 有非空名称菜单
    hasMenu() {
      // 空名称跳过
      const skiped = menuItem => menuItem.name == '';
      const week = this.getMenuWeek(skiped);
      const menu = flatMap(week);
      return menu.length > 0;
    },
    // 能否复制，发布周或空名称菜单不能复制
    disabledCopy() {
      const { startDate } = this.list.params;
      const current = dayjs(startDate).week();
      const publish = dayjs()
        .add(1, 'week')
        .week();
      return current == publish || !this.hasMenu;
    }
  },
  methods: {
    // 获得当前周菜单
    getMenuWeek(skipCallback) {
      const { items } = this;
      const week = [];
      for (let i = 0; i < 7; i++) {
        const day = [];
        items.forEach(item => {
          const menuItem = item[i];
          // 跳过检测
          const skipable = skipCallback(menuItem);
          if (skipable) {
            return;
          }
          if (menuItem.powders) {
            // 粉
            menuItem.powders?.forEach(it => {
              day.push(it);
            });
          } else if (menuItem.noodles) {
            // 面
            menuItem.noodles?.forEach(it => {
              day.push(it);
            });
          } else {
            // 其他
            day.push(menuItem);
          }
        });
        if (day.length > 0) {
          week.push(day);
        }
      }
      return week;
    },
    // 切换食堂
    async onCanteenTypeChange(e) {
      const { list, $refs } = this;
      const { params } = list;
      params.canteenType = e;
      await $refs.page.getItems();
    },
    // 上一周
    async onPrevious() {
      this.loading = true;
      const { list, $refs } = this;
      const { params } = list;
      // 以开始日期获得周，再减1周
      const { startDate } = params;
      params.startDate = dayjs(startDate)
        .subtract(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs(startDate)
        .subtract(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
      this.loading = false;
    },
    // 返回发布周
    async onCurrent() {
      this.loading = true;
      const { list, $refs } = this;
      const { params } = list;
      // 以当前日期获得周，再加1周
      params.startDate = dayjs()
        .add(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs()
        .add(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
      this.loading = false;
    },
    // 下一周
    async onNext() {
      this.loading = true;
      const { list, $refs } = this;
      const { params } = list;
      // 以开始日期获得周，再加1周
      const { startDate } = params;
      params.startDate = dayjs(startDate)
        .add(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      params.endDate = dayjs(startDate)
        .add(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      await $refs.page.getItems();
      this.loading = false;
    },
    // 复制，从其他周菜单复制到发布周菜单，并且跳过发布周菜单存在的
    async onCopy() {
      const { list, onCurrent, $refs } = this;
      // 缓存当前周参数
      const { startDate, endDate } = list.params;
      const cache = { startDate, endDate };
      // 跳回发布周
      await onCurrent();
      // 复制，由于日期参数不同，相当于从其他周查询菜单并赋值到发布周
      await $refs.page.getItems({
        ...cache,
        // 可复制的，复制时不赋值是否上架，不赋值编号，并且跳过存在的
        copyable: true
      });
    },
    // 保存，即下架
    async onSave(e, isOut = 0) {
      const { menu, $refs } = this;
      try {
        this.loading = true;
        if (isOut === 1){
          menu.forEach(item => (item.isOut = isOut));
        }
        await request.post('/canteen/menu/saveMenu', menu);
        await $refs.page.getItems();
      } finally {
        this.loading = false;
      }
    },
    // 发布，即上架
    async onPublish() {
      try {
        await this.$alert(
          '菜单一但发布就有可能有人预约，所以就不能再修改了，确认发布吗?',
          { confirmTextButton: '确认发布' }
        );
        // 值1为上架
        await this.onSave(undefined, 1);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
