export default {
  methods: {
    // 合并单元格
    spanMethod({ rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        // 类型列
        const span = { 0: [16, 1], 16: [14, 1], 30: [6, 1] };
        return span[rowIndex] || [0, 0];
      } else if (columnIndex == 2) {
        // 套餐列
        const span = {
          // 早餐
          // A
          0: [2, 1],
          // B
          2: [1, 1],
          // AB
          3: [12, 1],
          // 早餐 C
          15: [1, 1],
          // 中餐
          // A
          16: [7, 1],
          // B
          23: [4, 1],
          // C
          27: [1, 1],
          // D
          28: [2, 1],
          // 晚餐
          30: [6, 1]
        };
        return span[rowIndex] || [0, 0];
      } else {
        return undefined;
      }
    },
    // 行样式
    rowClassName({ rowIndex }) {
      if (rowIndex < 16) {
        return 'breakfast';
      } else if (rowIndex >= 16 && rowIndex < 30) {
        return 'lunch';
      } else {
        return 'dinner';
      }
    },
    // 单元格样式
    cellClassName({ row, columnIndex }) {
      if (columnIndex < 3) {
        return;
      }
      var cell = row[columnIndex - 4];
      if (cell?.isOut == 1) {
        return 'outed';
      }
    }
  }
};
