export default {
  data() {
    return {
      updater: this.getUpdater()
    };
  },
  methods: {
    getUpdater() {
      const target = this.getCreator();
      return Object.assign(target, {
        title: '编辑菜品',
        text: '编辑菜品',
        url: '/canteenCookbook/update'
      });
    }
  }
};
