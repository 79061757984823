export default {
  data() {
    return {
      number: {
        visible: false,
        width: '680px',
        selected: undefined,
        ifSelectable:true
      }
    };
  },
  methods: {
    onNumberCancel() {
      this.dialog.visible = false;
      this.number.visible = false;
    },
    onNumberConfirm(e) {
      const { dialog, number } = this;
      number.selected.totalNum = e.total;
      number.selected.maxNum = e.selectable;
      dialog.visible = false;
      number.visible = false;
    }
  }
};
