import dayjs from 'dayjs';

export default {
  methods: {
    // 填充空白单元格
    fillItems(vm) {
      const { startDate, canteenType } = vm.params;
      const length = 36;
      // 早餐有15行, 中餐有14行, 晚餐有6行
      for (let i = 0; i < length; i++) {
        const type = this.fillType(i);
        // 设置分类
        const category = this.fillCategory(type, i);
        // 设置套餐
        const combo = this.fillCombo(i);
        // 设置数量
        const totalNum = 999;
        // 行数据
        const row = {
          // y坐标
          y: i,
          // 类型
          type: '',
          // 套餐
          combo: '',
          // 类别
          category: '',
          // 总数量
          number: totalNum
        };
        // 星期列
        for (let j = 0; j < 7; j++) {
          // 每个单元格
          const cell = {
            // 编号
            id: undefined,
            // 图片路径
            picPath: undefined,
            // 名称
            name: '',
            // 类型
            type,
            // 类别
            category,
            // 套餐
            combo,
            // 价格
            price: 0,
            // 总数量
            totalNum,
            // 预约日期
            menuDate: dayjs(startDate)
              .add(j, 'day')
              .format('YYYY-MM-DD'),
            // 下架状态（0下架，1上架 ）
            isOut: 0,
            // 细类
            isSet: 0,
            // 食堂
            canteenType,
            // X坐标
            tableX: j,
            // Y坐标
            tableY: i,
            // // 预约数量
            subscribeNum:undefined
          };
          row[j] = cell;
          // 第1行粉
          if (i == 0) {
            // 粉细类
            row[j].isSet = 1;
            row[j].powders = this.fillPowders(cell);
          }
          // 第2行面
          if (i == 1) {
            // 面细类
            row[j].isSet = 2;
            row[j].noodles = this.fillNoodles(cell);
          }
        }
        // 添加行
        vm.items.push(row);
      }
    },
    // 填充分类
    // 0-15 早餐，16-29中餐，30以上晚餐
    fillType(row) {
      if (row < 16) {
        return 1;
      } else if (row >= 16 && row < 30) {
        return 2;
      } else {
        return 3;
      }
    },
    // 设置类别
    fillCategory(type, row) {
      const categories = {
        // 早餐有16行
        // 5类别，1粉面2粥3面点/蛋4饮品5套餐
        1: [1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 6],
        // 中餐有14行
        // 7类别，1主荤2副荤3素菜4例汤5粉面6营养7水饺
        2: [1, 2, 2, 2, 3, 3, 4, 7, 1, 3, 3, 5, 6, 1],
        // 晚餐有6行
        // 7类别，1主荤2副荤3素菜4例汤5粉面6营养7水饺
        3: [1, 2, 2, 3, 3, 4]
      };
      // 行偏移，中餐从16行开始，晚餐从30行开始
      const offsets = [undefined, 0, 16, 30];
      const offset = row - offsets[type];
      // 返回早中餐的类别定义
      return categories[type][offset];
    },
    // 填充套餐
    fillCombo(row) {
      if (
        row == 0 ||
        row == 1 ||
        (row >= 16 && row <= 22) ||
        (row >= 30 && row <= 35)
      ) {
        return 'A';
      } else if (row == 2 || (row >= 23 && row <= 26)) {
        return 'B';
      } else if (row == 15 || row == 27) {
        return 'C';
      } else if (row >= 28 && row <= 29) {
        return 'D';
      }
    },
    // 填充数量
    fillNumber(row) {
      if ([15].includes(row)) {
        // 早餐C套餐
        return 20;
      } else if ([23, 24, 25, 26].includes(row)) {
        // 午餐B套餐
        return 100;
      } else if ([27].includes(row)) {
        // 中餐C套餐
        return 40;
      } else if ([28, 29].includes(row)) {
        // 水饺D套餐
        return 30;
      } else {
        // 其他
        return 999;
      }
    },
    // 粉细类123两，切榨
    fillPowders(cell) {
      const suffixs = [
        '(1两)(切)',
        '(2两)(切)',
        '(3两)(切)',
        '(1两)(榨)',
        '(2两)(榨)',
        '(3两)(榨)'
      ];
      return suffixs.map((suffix, index) => {
        return {
          ...cell,
          name: '',
          suffix,
          // 粉面类
          isSet: 1,
          // 粉面类下标
          isPush: index
        };
      });
    },
    // 面细类123两
    fillNoodles(cell) {
      const suffixs = ['(1两)', '(2两)', '(3两)'];
      return suffixs.map((suffix, index) => {
        return {
          ...cell,
          name: '',
          suffix,
          // 细类
          isSet: 2,
          // 细类下标
          isPush: index
        };
      });
    },
    // 设置行头
    setRowHeader(vm) {
      // 餐型列
      vm.items[0].type = '早餐';
      vm.items[16].type = '中餐';
      vm.items[30].type = '晚餐';
      // 套餐列
      // 早
      vm.items[0].combo = 'A';
      vm.items[2].combo = 'B';
      vm.items[3].combo = 'A\nB';
      vm.items[15].combo = 'C';
      // 中
      vm.items[16].combo = 'A';
      vm.items[23].combo = 'B';
      vm.items[27].combo = 'C';
      vm.items[28].combo = 'D';
      // 餐
      vm.items[30].combo = 'A';
      // 类别列
      // 早
      vm.items[0].category = '粉';
      vm.items[1].category = '面';
      vm.items[2].category = '粥';
      vm.items[3].category = '面点1';
      vm.items[4].category = '面点2';
      vm.items[5].category = '面点3';
      vm.items[6].category = '面点4';
      vm.items[7].category = '面点5';
      vm.items[8].category = '面点6';
      vm.items[9].category = '面点7';
      vm.items[10].category = '面点8';
      vm.items[11].category = '蛋1';
      vm.items[12].category = '蛋2';
      vm.items[13].category = '豆浆';
      vm.items[14].category = '饮品';
      vm.items[15].category = '套餐(20)';
      // 中，A餐
      vm.items[16].category = '主荤';
      vm.items[17].category = '副荤1';
      vm.items[18].category = '副荤2';
      vm.items[19].category = '副荤3';
      vm.items[20].category = '素菜1';
      vm.items[21].category = '素菜2';
      vm.items[22].category = '例汤';
      // 中，B餐
      vm.items[23].category = '粉面(100)';
      vm.items[24].category = '主荤(100)';
      vm.items[25].category = '素菜1(100)';
      vm.items[26].category = '素菜2(100)';
      // 中，C餐
      vm.items[27].category = '营养(40)';
      // 中，D餐
      vm.items[28].category = '水饺(30)';
      vm.items[29].category = '主荤(30)';
      // 晚
      vm.items[30].category = '主荤';
      vm.items[31].category = '副荤1';
      vm.items[32].category = '副荤2';
      vm.items[33].category = '素菜1';
      vm.items[34].category = '素菜2';
      vm.items[35].category = '例汤';
    },
    // 设置列头
    setColumnHeader(vm) {
      const { params, fields } = vm;
      const { startDate } = params;
      // 从第3列开始
      const begin = 3;
      // 本周开始的日期
      for (let i = begin; i < fields.length; i++) {
        const field = fields[i];
        // 日期
        const date = dayjs(startDate)
          .add(i - begin, 'day')
          .format('YYYY-MM-DD');
        // 星期
        const week = dayjs(startDate)
          .add(i - begin, 'day')
          .format('ddd');
        // 设置表头文本
        field.label = `${date},${week}`;
      }
    }
  }
};
